<template>
    <div>
        <app-table
            :columns="columns"
            :actions="tableActions"
            url="sites"
            ref="table"
            @ajaxSuccess="handleTableAjaxSuccess"
        >
            <template v-slot:actions>
                <v-btn 
                    small 
                    color="primary" 
                    dark 
                    class="mr-2"
                    :to="{ name: 'customer.sites.create' }"
                >
                    <v-icon left dense>fal fa-plus</v-icon> Add
                </v-btn>
            </template>
            <template v-slot:item.title="{ item }">
                {{ item.title }} 
                <span v-if="item.types.length">
                    <v-chip 
                        small 
                        color="teal" 
                        outlined 
                        v-for="type in item.types"
                        :key="type"
                        class="tw-ml-1"
                    >{{ type }}</v-chip>
                </span>
            </template>
        </app-table>
        <add-modal ref="addModal" @success="$refs.table.init()" />
        <edit-modal ref="editModal" @success="$refs.table.init()" />
    </div>
</template>

<script>
    import AppTable from '@/components/Table.vue';
    import Http from '@/utils/httpClient';
    import AddModal from './modals/Add.vue';
    import EditModal from './modals/Edit.vue';

    export default {
        components: {
            AppTable,
            AddModal,
            EditModal,
        },
        data() {
            return {
                countries: [],
                addresses: [],
                types: [],
                statuses: [],
                columns: [                    
                    {
                        text: 'Name',
                        value: 'title',
                        slotName: 'item.title',
                        sortable: false,
                        width: 150,
                    },
                    {
                        text: 'Address',
                        value: 'complete_address_for_human',
                        sortable: false,
                        width: 250,
                    },
                    {
                        text: 'Status',
                        value: 'status',
                        sortable: false,
                        width: 70,
                    },
                ],
                tableActions: [
                    {
                        label: 'Edit',
                        icon: 'fal fa-pencil fa-fw',
                        handler: (site) => {
                            this.$router.push({
                                name: 'customer.sites.edit',
                                params: {
                                    siteId: site.id,
                                }
                            })
                        }
                    },                 
                    {
                        label: 'Delete',
                        icon: 'fal fa-trash-alt fa-fw',
                        handler: (item) => {
                            this.$root.$confirm({
                                title: 'Are you sure?',
                                message: `You are about to delete ${item.title} permanently.`,
                                agreeButtonText: 'Yes, delete it',
                                agreeButtonColor: 'error',
                            })
                            .then(() => {
                                this.$root.$loading.open({ text: `Deleting ${item.title} permanently...` });
                                Http.delete(`sites/${item.id}`)
                                    .then(() => {
                                        this.$root.$loading.close();
                                        this.$root.$snackbar.open({
                                            text: `${item.title} was successfully deleted.`,
                                            type: 'success',
                                        });
                                        this.$refs.table.init();
                                    })
                                    .catch(() => this.$root.$loading.close());
                            })
                            .catch(() => {
                                this.$root.$snackbar.open({
                                    text: 'Action cancelled',
                                    type: 'info',
                                });
                            })
                        }
                    }
                ],
            }
        },
        methods: {
            handleTableAjaxSuccess(data) {
                this.countries = data.countries;
                this.addresses = data.addresses;
                this.types = data.types;
                this.statuses = data.statuses;
            }
        }
    }
</script>
