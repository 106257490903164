<template>
    <v-dialog
        v-model="show"
        persistent
        max-width="400"
        scrollable
    >
        <v-card>
            <v-card-title>Edit Site</v-card-title>
            <v-card-text class="mt-3">
                <v-text-field
                    v-model="form.title"
                    :error-messages="form.errors.get('title')"
                    @input="form.errors.clear('title')"
                    label="Title (Required)"
                    autofocus
                />
                <v-text-field
                    v-model="form.description"
                    :error-messages="form.errors.get('description')"
                    @input="form.errors.clear('description')"
                    label="Description"
                />
                <v-select
                    v-model="form.types"
                    :items="types"
                    item-text="title"
                    item-value="name"
                    label="Types (Required)"
                    multiple
                    chips
                    deletable-chips
                    :error-messages="form.errors.get('types')"
                    @input="form.errors.clear('types')"
                />
                <v-select
                    v-model="form.status"
                    :items="statuses"
                    item-text="title"
                    item-value="id"
                    label="Status (Required)"
                    :error-messages="form.errors.get('status')"
                    @input="form.errors.clear('status')"
                />
                <v-select
                    v-model="form.country_id"
                    :items="countries"
                    item-text="name"
                    item-value="id"
                    label="Country (Required)"
                    :error-messages="form.errors.get('country_id')"
                    @input="form.errors.clear('country_id')"
                    @change="handleCountryChanged"
                />
                <v-text-field
                    v-model="form.street_one"
                    :error-messages="form.errors.get(`street_one`)"
                    @input="form.errors.clear(`street_one`)"
                    label="Address line 1 (Required)"
                />
                <v-text-field
                    v-model="form.street_two"
                    :error-messages="form.errors.get(`street_two`)"
                    @input="form.errors.clear(`street_two`)"
                    label="Address line 2"
                />
                <v-text-field
                    v-model="form.suburb"
                    :error-messages="form.errors.get(`suburb`)"
                    @input="form.errors.clear(`suburb`)"
                    label="Suburb (Required)"
                />
                <v-select
                    v-model="form.region_id"
                    :items="regions"
                    item-text="name"
                    item-value="id"
                    label="State/Province (Required)"
                    :error-messages="form.errors.get(`region_id`)"
                    @input="form.errors.clear(`region_id`)"
                    no-data-text="Select a country first."
                />
                <v-text-field
                    v-model="form.postcode"
                    :error-messages="form.errors.get(`postcode`)"
                    @input="form.errors.clear(`postcode`)"
                    label="ZIP/Postal code (Required)"
                />
                <app-map 
                    :address="addressToString(form)"                         
                    @position="handleMapPosition($event)"
                />
            </v-card-text>
            <v-card-actions class="pt-4">
                <v-spacer />
                <v-btn
                    text                     
                    :disabled="saving"
                    @click="close"
                >Cancel</v-btn>
                <v-btn 
                    text
                    color="primary"
                    :loading="saving"
                    @click="save"
                >Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Form from '@/utils/form';
    import Http from '@/utils/httpClient';
    import AppMap from '@/components/Map.vue';

    export default {        
        components: {
            AppMap,
        },
        data() {
            return {
                saving: false,
                show: false,
                countries: [],
                regions: [],
                addresses: [],
                types: [],
                statuses: [],
                form: new Form({
                    title: null,
                    types: null,
                    description: null,
                    country_id: null,
                    street_one: null,
                    street_two: null,
                    suburb: null,
                    region_id: null,
                    postcode: null,
                    lat: null,
                    lon: null,
                    status: null,
                }),
            }
        },
        watch: {
            $route() {
                this.init();
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                if (this.$route.name === 'customer.sites.edit') {
                    this.open();
                }
            },
            open() {
                this.$root.$loading.open();
                Http.get(`sites/${this.$route.params.siteId}/edit`)
                    .then(({ data }) => {
                        this.$root.$loading.close();
                        this.types = data.types;
                        this.countries = data.countries;
                        this.statuses = data.statuses

                        this.form.set({
                            title: data.site.title,
                            description: data.site.description,
                            types: data.site.types,
                            country_id: data.site.country_id,
                            region_id: data.site.address.region_id,
                            street_one: data.site.address.street_one,
                            street_two: data.site.address.street_two,
                            suburb: data.site.address.suburb,
                            postcode: data.site.address.postcode,
                            lat: data.site.address.lat,
                            lon: data.site.address.lon,
                            status: data.site.status,
                        });
                        
                        this.handleCountryChanged(data.site.country_id);
                        this.show = true;
                    })
                    .catch(() => {
                        this.$root.$loading.close();
                        this.close();
                    });                
            },
            close() {
                this.show = false;
                this.$router.push({
                    name: this.$route.meta.parent,
                })
            },
            handleCountryChanged(id) {
                this.regions = this.countries.find((country) => country.id === id).regions;
            },
            addressToString(address) {
                const country = this.countries.find((c) => c.id === address.country_id);
                const region = this.regions.find((r) => r.id === address.region_id);

                return `${address.street_one || ''} ${address.street_two || ''} ${address.suburb || ''} ${address.postcode || ''} ${region ? region.name : ''} ${country ? country.name : ''}`.replace(/\s+/g, ' ').trim(); 
            },
            handleMapPosition(position) {
                this.form.lat = position.lat;
                this.form.lon = position.lng;
            },
            save() {
                this.saving = true;
                Http.patch(`sites/${this.$route.params.siteId}`, this.form.payload())
                    .then(({ data }) => {
                        this.saving = false;
                        this.close();
                        this.$root.$snackbar.open({
                            text: `Site ${this.form.title} was successfully added.`,
                            type: 'success',
                        });
                        this.$emit('success', data);
                    })
                    .catch(({ response }) => {
                        this.saving = false;
                        this.form.errors.record(response.data);
                    });
            }
        }
    }    
</script>
