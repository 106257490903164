<template>
    <div class="tw-h-full">
        <div class="d-flex align-center">
            <v-text-field
                v-model="lat"
                label="Latitude"
            />
            <v-text-field
                v-model="lng"
                label="Longitude"
                class="ml-4"
            />
        </div>
        <div 
            :id="`map${_uid}`" 
            style="height: calc(100% - 70px)"
            class="tw-shadow"
        ></div>
    </div>
</template>

<script>
    import debounce from 'lodash.debounce';

    export default {
        props: {
            address: {
                type: String,
                default: null,
            },
            latitude: {
                type: [String, Number],
                default: -32.0518191
            },
            longitude: {
                type: [String, Number],
                default: 115.7551085,
            }
        },
        data() {
            return {
                map: null,
                markers: [],
                lat: null,
                lng: null,
                geocoder: null,
                draggingMarker: false,
            }
        },
        watch: {
            address(newAddress) { 
                if (newAddress) {
                    this.geocodeAddress();
                }
            },
            lat(newLat) {
                if (!this.draggingMarker) {
                    this.addMarker({
                        lat: parseFloat(newLat),
                        lng: parseFloat(this.lng),
                    });
                }
            },
            lng(newLng) {
                if (!this.draggingMarker) {
                    this.addMarker({
                        lat: parseFloat(this.lat),
                        lng: parseFloat(newLng),
                    });
                }
            }
        },    
        mounted() {
             this.injectScript();
        },
        methods: {
            init() {
                this.lat = this.latitude || -32.0518191;
                this.lng = this.longitude || 115.7551085;

                const center = {
                    lat: this.lat,
                    lng: this.lng,
                };
                this.map = new window.google.maps.Map(document.getElementById(`map${this._uid}`), {
                    center,
                    zoom: 11
                });

                this.geocoder = new window.google.maps.Geocoder();

                if (this.address) {
                    this.geocodeAddress();
                } else {
                    this.addMarker(center);
                }
            },
            injectScript() {
                const src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}&libraries=&v=weekly`
                let script = document.querySelector(`script[src="${src}"]`);

                if (script) {
                    this.init();
                } else {
                    script = document.createElement('script');
                    script.setAttribute('src', src);

                    script.onload = () => {
                        this.init();
                    };

                    document.head.appendChild(script);
                }
            },
            geocodeAddress: debounce(function () {
                this.geocoder.geocode({ address: this.address }, (results, status) => {
                    if (status === 'OK') {                        
                        this.addMarker(results[0].geometry.location);
                        this.lat = results[0].geometry.location.lat();
                        this.lng = results[0].geometry.location.lng();
                        this.emitPosition();    
                    }
                });
            }, 1000),
            addMarker(position) {
                this.deleteMarkers();
                const marker = new window.google.maps.Marker({
                    map: this.map,
                    position,
                    draggable: true,
                    title: 'Drag me'
                });

                this.map.setCenter(marker.getPosition());

                marker.addListener('drag', () => {
                    this.draggingMarker = true;
                    this.lat = marker.getPosition().lat();
                    this.lng = marker.getPosition().lng();
                    this.emitPosition();
                });

                marker.addListener('dragend', () => {
                    this.draggingMarker = false;
                })

                this.emitPosition();
                this.markers.push(marker); 
            },
            deleteMarkers() {
                for (let i = 0; i < this.markers.length; i += 1) {
                    this.markers[i].setMap(null);
                }

                this.markers = [];
            },
            emitPosition() {
                this.$emit('position', {
                    lat: this.lat,
                    lng: this.lng,
                });
            }
        }  
    }
</script>
